import { useState, useEffect, useRef } from "react";
import { StyledInput } from "./style";
import { Button, Overlay } from "react-bootstrap";

export const InputCard = (props: {
  disabled?: boolean;
  value: string;
  otherValue: string;
  onChange: (v: string) => void;
}) => {
  const { otherValue } = props;
  const [value, setValue] = useState(props.value);

  const [showTool, setShowTool] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const inputRef = useRef<any>(null);

  const allowedCharacters = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "T",
    "J",
    "Q",
    "K",
    "A",
  ];

  const allowedInput = ["1", "0", "BACKSPACE", "DELETE", ...allowedCharacters];

  let lastInput = "";

  const handleInput = (e) => {
    const target = e.target;
    const v = target.value.toUpperCase();
    if (v === "") {
      setValue(v);
      return;
    }
    const position = target.selectionStart;
    const last2 = v.slice(position - 2, position);
    let result = "";
    if (last2.length === 2 && last2.startsWith("1")) {
      switch (last2[1]) {
        case "0":
          result = "T";
          break;
        case "1":
          result = "J";
          break;
        case "2":
          result = "Q";
          break;
        case "3":
          result = "K";
          break;
        case "4":
          result = "A";
          break;
        default:
      }

      //If it's invalid, remove previous one
      if (result === "") {
        const newValue = v.slice(0, position - 2) + v.slice(position, v.length);
        if (setNewValue(newValue)) {
          inputRef.current.setSelectionRange(position - 1, position - 1);
        }
      } else {
        const newValue =
          v.slice(0, position - 2) + result + v.slice(position, v.length);
        if (setNewValue(newValue, result)) {
          inputRef.current.setSelectionRange(position - 1, position - 1);
        }
      }
    } else if (allowedInput.includes(lastInput)) {
      setNewValue(v, lastInput);
    } else {
    }
  };

  //set new value
  const setNewValue = (v: string, newCharacter?: string) => {
    if (v.length <= 13) {
      if (
        !newCharacter ||
        (newCharacter &&
          !value.includes(newCharacter) &&
          !otherValue.includes(newCharacter))
      ) {
        inputRef.current.value = v;
        setValue(v);
        return true;
      }
    }
    return false;
  };

  const handleBlur = () => {
    let newValue = value;

    //remove unallowed
    for (const c of value) {
      if (!allowedCharacters.includes(c)) {
        newValue = newValue.replaceAll(c, "");
      }
    }

    //sort
    const sortArr = {
      "2": 2,
      "3": 3,
      "4": 4,
      "5": 5,
      "6": 6,
      "7": 7,
      "8": 8,
      "9": 9,
      T: 10,
      J: 11,
      Q: 12,
      K: 13,
      A: 14,
    };
    const arr = newValue.split("");
    arr.sort((a, b) => (sortArr[b] - sortArr[a] > 0 ? 1 : -1));
    const newValueSorted = arr.join("");
    setValue(newValueSorted);

    props.onChange(newValueSorted);
    setShowTool(false);
  };

  return (
    <div>
      <StyledInput
        ref={inputRef}
        value={value}
        onFocus={() => {
          setShowTool(true);
        }}
        onKeyDown={(e) => {
          lastInput = e.key.toUpperCase();
        }}
        disabled={props.disabled}
        onChange={handleInput}
        onBlur={handleBlur}
      />
      <Overlay target={inputRef} show={showTool} placement="right">
        <div
          style={{
            display: "flex",
            gap: 2,
            background: "#f0f0f0",
            border: "1px solid #b1b1b1",
            marginLeft: 3,
            borderRadius: 4,
          }}
        >
          <Button
            size="sm"
            title="Kompletter farge"
            variant="link"
            onClick={() => {
              props.onChange(getRestCards(otherValue));
              inputRef.current.focus();
            }}
          >
            <i className="bi bi-card-list"></i>
          </Button>
          <Button
            title="Tøm felt"
            size="sm"
            variant="link"
            onClick={() => {
              props.onChange("");
              inputRef.current.focus();
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      </Overlay>
    </div>
  );
};

const getRestCards = (v: string) => {
  const totalCards = "AKQJT98765432";
  let restCards = "";
  for (const i of totalCards) {
    if (!v.includes(i)) {
      restCards += i;
    }
  }
  return restCards;
};
