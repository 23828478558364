import { siteEnv } from "./siteEnv";

export const getNiceUrl = (identifierPath: string) => {
  let arr = identifierPath.split("/");
  return wrapBasePath(arr.slice(2).join("/"));
};

export const wrapBasePath = (url: string) => {
  if (siteEnv.basePath) {
    return "/" + siteEnv.basePath + url;
  } else {
    return url;
  }
};

export const getArticleNiceUrl = (location: any) => {
  const arr = location.identifier_path.split("/");
  let niceUrl = arr[arr.length - 1];
  return wrapBasePath("/detail/" + niceUrl + "/" + location.id);
};

export const getIdentifierPath = (path: string) => {
  return "content/hjerter-ess/" + path;
};

export const imagePath = (path, thumbnail?: boolean) => {
  if (
    path.startsWith("http://") ||
    path.startsWith("https://") ||
    path.startsWith("data:")
  ) {
    return path;
  } else {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return (
        "http://adzt2clavc.dcp.dev.digimaker.no/var/" +
        (thumbnail ? "images/thumbnail/" : "images/full/") +
        path
      );
    } else {
      return (
        "https://bridge.c.digimaker.com/var/" +
        (thumbnail ? "images/thumbnail/" : "images/full/") +
        path
      );
    }
  }
};

export const serverFetchWithAuth = async (bodyObj: any) => {
  try {
    const res = await fetch(`${process.env.graphqlUrl}`, {
      headers: { apiKey: `${process.env.apiKey}` },
      method: "POST",
      body: JSON.stringify({ query: `${bodyObj}` }),
    });
    const data = await res.json();
    return data;
  } catch (e) {
    console.error("Widget fetching error on", bodyObj);
    throw e;
  }
};
