import styled from "@emotion/styled";
export const SpillTable = styled.table`
  min-width: 300px;
  & > tr > td {
    min-width: 33.333%;
  }
`;

export const StyledInput = styled.input`
  border: 1px solid #cccccc;
  width: 100px;
`;

export const CardTypeLabel = styled.span`
  display: inline-block;
  font-size: 18px;
  width: 20px;
`;

export const PositionPanelRow = styled.div`
  display: flex;

  label > input {
    margin-right: 3px;
  }
`;

export const GiverItem = styled.td<{ selected?: boolean; editMode?: boolean }>`
  text-align: center;
  width: 30px;
  height: 30px;
  ${(props) =>
    props.selected ? { color: "white", backgroundColor: "#666666" } : {}}
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  ${(props) => (props.editMode ? { cursor: "pointer" } : {})}
`;

export const GiverTable = styled.table`
  min-width: 120px;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
`;

export const GiverRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

export const Tool = styled.div`
  padding: 10px 0px;
  text-align: center;
`;

export const BridgeSpillContainer = styled.div`
  display: flex;
  gap: 40px;

  .dme-viewmode-mobile & {
    flex-direction: column;
  }
`;

export const DivWithBreak = styled.div`
  white-space: pre-line;
`;

export const RootDiv = styled.div`
  padding: 10px;
`;

export const BiddingText = styled.div`
  padding: 5px;
  font-size: 14px;
  min-width: 60px;
`;
