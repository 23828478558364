import { DME, RenderToSetting, useDevice, useEditorStore } from "dmeditor";
import {
  BridgeSpillContainer,
  DivWithBreak,
  RootDiv,
  SpillTable,
  Tool,
} from "./style";

import { Alert, Button, Modal, Overlay, Toast } from "react-bootstrap";

import { css } from "@emotion/css";
import { EntityBridgeSpill, SpillOneRecord, SpillRecord } from "./entity";
import { useEffect, useRef, useState } from "react";
import { Bidding } from "./Bidding";
import { PositionPanel } from "./PositionPanel";
import { GiverZone } from "./GiverZone";
import { Board } from "./Board";

export const BridgeSpill = (
  props: DME.WidgetRenderProps<EntityBridgeSpill>
) => {
  const { inputData } = props.blockNode.data;

  const [currentData, setCurrentData] = useState(inputData);

  const { updateBlockByPath } = useEditorStore();

  const [currentMode, setCurrentMode] = useState<"view" | "edit">(
    props.blockNode.data.new ? "edit" : "view"
  );

  const [showAnswer, setShowAnswer] = useState(false);

  const [validationResult, setValidationResult] = useState<
    Array<{
      message: string;
      position: string;
    }>
  >([]);

  const questionButtonRef = useRef(null);

  const getOtherData = (position: "north" | "south" | "west" | "east") => {
    const data = {
      north: currentData.north,
      south: currentData.south,
      west: currentData.west,
      east: currentData.east,
    };

    const result = { spade: "", heart: "", diamond: "", club: "" };
    Object.keys(data).map((key) => {
      if (key !== position) {
        result.spade += data[key].spade.data;
        result.heart += data[key].heart.data;
        result.diamond += data[key].diamond.data;
        result.club += data[key].club.data;
      }
    });
    return result;
  };

  const UpdatePanelData = (
    data: SpillRecord,
    position: "north" | "south" | "west" | "east"
  ) => {
    let newData = { ...currentData, [position]: data };
    setCurrentData(newData);
  };

  const validate = () => {
    const cardData: Array<Array<string>> = [];
    ["north", "south", "west", "east"].map((position, i) => {
      const positionData = currentData[position] as SpillRecord;
      const positionArr = ["", "", "", ""];
      ["spade", "heart", "diamond", "club"].map((card, j) => {
        const data = (positionData[card] as SpillOneRecord).data;
        positionArr[j] = data;
      });
      cardData.push(positionArr);
    });

    const playerCardCount = [0, 0, 0, 0];
    const cards = ["", "", "", ""];
    for (const i in cardData) {
      for (const j in cardData[i]) {
        const itemData = cardData[i][j];
        playerCardCount[i] += itemData.length;
        cards[j] += itemData;
      }
    }

    const positions = ["north", "south", "west", "east"];
    const result: typeof validationResult = [];
    for (const i in playerCardCount) {
      if (playerCardCount[i] > 13) {
        result.push({
          message: "Maks. 13 kort på hånden. - " + playerCardCount[i],
          position: positions[i],
        });
      }
    }

    //todo: check duplication

    return result;
  };

  const save = () => {
    const vResult = validate();
    if (vResult.length === 0) {
      updateBlockByPath<EntityBridgeSpill>(props.path, (blockData) => {
        blockData.inputData = currentData;
        blockData.new = false;
      });
      setValidationResult([]);
      setCurrentMode("view");
    } else {
      setValidationResult(vResult);
    }
  };

  const cancel = () => {
    updateBlockByPath<EntityBridgeSpill>(props.path, (blockData) => {
      blockData.new = false;
    });
    setCurrentData(inputData);
    setCurrentMode("view");
  };

  const renderGiver = () => {
    return (
      <GiverZone
        mode={currentMode}
        giver={currentData.giver}
        onGiverChange={(v) => {
          if (currentMode === "edit") {
            setCurrentData({ ...currentData, giver: v });
          }
        }}
        zone={currentData.zone}
        onZoneChange={(v) => {
          if (currentMode === "edit") {
            setCurrentData({ ...currentData, zone: v });
          }
        }}
      />
    );
  };

  return (
    <RootDiv>
      {props.active && (
        <RenderToSetting>
          <div
            className={css`
              padding: 10px;
            `}
          >
            <Button
              disabled={currentMode === "edit"}
              onClick={() => setCurrentMode("edit")}
              size="sm"
            >
              <i className="fa fa-edit"></i> Redigere
            </Button>
          </div>
        </RenderToSetting>
      )}
      <BridgeSpillContainer>
        <SpillTable>
          <tr>
            <td style={{ paddingRight: 20 }}>
              {currentMode === "edit" && renderGiver()}
            </td>
            <td colSpan={2} style={{ paddingBottom: 10 }}>
              <PositionPanel
                data={currentData.north}
                mode={currentMode}
                onChange={(data) => UpdatePanelData(data, "north")}
                restData={getOtherData("north")}
                position="north"
              />
            </td>
          </tr>
          <tr>
            <td style={{ paddingRight: 10 }}>
              <PositionPanel
                data={currentData.west}
                mode={currentMode}
                onChange={(data) => UpdatePanelData(data, "west")}
                restData={getOtherData("west")}
                position="west"
              />
            </td>
            <td>
              <Board
                zone={currentData.zone}
                editMode={currentMode === "edit"}
                giver={currentData.giver}
                boardNo={currentData.boardNo}
                onBoardNoChange={(v) =>
                  setCurrentData({ ...currentData, boardNo: v })
                }
              />
            </td>
            <td style={{ paddingLeft: 10 }}>
              <PositionPanel
                data={currentData.east}
                mode={currentMode}
                onChange={(data) => UpdatePanelData(data, "east")}
                restData={getOtherData("east")}
                position="east"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2} style={{ paddingTop: 10 }}>
              <PositionPanel
                data={currentData.south}
                mode={currentMode}
                onChange={(data) => UpdatePanelData(data, "south")}
                restData={getOtherData("south")}
                position="south"
              />
            </td>
          </tr>
        </SpillTable>

        <div>
          <div>
            <Bidding
              editMode={currentMode === "edit"}
              giver={currentData.giver}
              biddingData={currentData.bidding}
              onChange={(obj) =>
                setCurrentData({ ...currentData, bidding: obj })
              }
            />
            <div
              className={css`
                margin-top: 10px;
              `}
            >
              {currentMode === "edit" && (
                <>
                  <div>
                    <textarea
                      placeholder="Kommentar/Oppgave"
                      className="form-control"
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          question: e.target.value,
                        })
                      }
                    >
                      {currentData.question}
                    </textarea>
                  </div>
                  <div
                    className={css`
                      margin-top: 10px;
                      position: relative;
                    `}
                  >
                    <textarea
                      style={{ position: "absolute", zIndex: 1000 }}
                      rows={4}
                      placeholder="Løsning/Svar på oppgave"
                      className="form-control"
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          answer: e.target.value,
                        })
                      }
                    >
                      {currentData.answer}
                    </textarea>
                  </div>
                </>
              )}
              {currentMode === "view" && currentData.question && (
                <>
                  <DivWithBreak>
                    <p>{currentData.question}</p>
                  </DivWithBreak>
                  <div
                    className={css`
                      margin-top: 10px;
                    `}
                  >
                    <button
                      ref={questionButtonRef}
                      className="btn btn-success btn-sm"
                      onClick={() => setShowAnswer(!showAnswer)}
                    >
                      Se løsning
                    </button>
                    <Toast
                      show={showAnswer}
                      className={css`
                        .dme-viewmode-mobile & {
                          left: 0px;
                        }
                      `}
                      style={{
                        position: "absolute",
                        marginTop: 5,
                        background: "white",
                        zIndex: 1000,
                      }}
                      onClose={() => setShowAnswer(false)}
                    >
                      <Toast.Header className="justify-content-between">
                        <b>Løsning</b>
                      </Toast.Header>
                      <Toast.Body>
                        <DivWithBreak>
                          <p>{currentData.answer}</p>
                        </DivWithBreak>
                      </Toast.Body>
                    </Toast>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </BridgeSpillContainer>

      {currentMode === "edit" && validationResult.length > 0 && (
        <Alert variant="warning">
          {validationResult.map((item) => (
            <div>
              {
                {
                  north: "Nord",
                  south: "Sør",
                  west: "Vest",
                  east: "Øst",
                }[item.position]
              }
              : {item.message}
            </div>
          ))}
        </Alert>
      )}

      {currentMode === "edit" && (
        <Tool>
          <Button onClick={save} size="sm">
            <i className="bi bi-save"></i> &nbsp;Lagre
          </Button>
          &nbsp;
          <Button onClick={cancel} size="sm" variant="light">
            Avbryt
          </Button>
        </Tool>
      )}
    </RootDiv>
  );
};
