import { EntityNewsList } from "./entity";
import { DME, useDevice } from "dmeditor";

import { TitleOutlined } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { FetchWithAuth } from "digimaker-ui/util";

import { Pagination, PaginationItem } from "@mui/material";
import Moment from "react-moment";
import { Link } from "../Shared";
import { getArticleNiceUrl, imagePath, serverFetchWithAuth } from "../util";
import { siteEnv } from "../siteEnv";

const NewsList = (props: DME.WidgetRenderProps<EntityNewsList>) => {
  const {
    blockNode: { data },
  } = props;

  const [list, setList] = useState(data.list);

  const [page, setPage] = useState(data.page || 1);
  const limit = data.settings.limit || 10;

  const calculateCount = (total) => {
    return total % limit == 0
      ? parseInt((total / limit).toString())
      : parseInt((total / limit).toString()) + 1;
  };

  const [count, setCount] = useState(() => {
    let total: any = data.count || 0;
    return calculateCount(total);
  });

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchList();
    }
  }, [page, data.settings.limit]);

  const fetchList = () => {
    const parentID = siteEnv.folderNews;
    FetchWithAuth(
      "content/list/article?parent=" +
        parentID +
        "&location.is_hidden=false&sortby=priority%20desc%3Bpublished%20desc&offset=" +
        (page - 1) * limit +
        "&limit=" +
        limit
    ).then((data) => {
      setList(data.data.list);
      setCount(calculateCount(data.data.count));
    });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (!data.list.length) {
      setPage(value);
    } else {
      window.location.href = window.location.pathname + `?page=${value}`;
    }
  };

  if (!list) {
    return <div></div>;
  }

  return (
    <div>
      <div className="listBox bg article_list">
        <div className="w-container">
          <div className="listcontent">
            {list.map((item: any) => {
              return (
                <Link
                  key={item.id}
                  className="listItem"
                  to={getArticleNiceUrl(item.location)}
                >
                  <div className="listImage">
                    <img
                      src={
                        item.coverimage == ""
                          ? "/images/bg2.jpg"
                          : imagePath(item.coverimage, true)
                      }
                    />
                  </div>
                  <div className="listText">
                    <div className="content-title font-Cambria-blod">
                      <span>{item.title}</span>
                      <span className="time">
                        {item.is_archived && item.comment ? (
                          item.comment
                        ) : (
                          <Moment format="DD.MM.YYYY HH:mm">
                            {item.metadata.published}
                          </Moment>
                        )}
                      </span>
                    </div>
                    <div
                      className="content-body"
                      dangerouslySetInnerHTML={{ __html: item.summary }}
                    ></div>
                    <div
                      className="content-btn text-right"
                      style={{ textAlign: "right" }}
                    >
                      <Link to={getArticleNiceUrl(item.location)}>
                        <Button className="font-Cambria-blod" variant="warning">
                          MER<i className="bi bi-arrow-right"></i>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          {!data.settings.hidePagination && (
            <div className="listPage" style={{ paddingBottom: 20 }}>
              <Pagination
                className="pagination"
                count={count}
                shape="rounded"
                page={page}
                onChange={handleChange}
                color="primary"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: () => <>Forrige</>,
                      next: () => <>Neste</>,
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const onServerLoad = async (existingData, { query, siteEnv }) => {
  const parent = siteEnv.folderNews;
  const limit = existingData.data.settings.limit || 10;

  let page = query.page ? parseInt(query.page) : 1;
  let offset = (page - 1) * limit;

  let bodyObj: any = `query{
        article(filter:{_location_parent_id:${parent}, _location_is_hidden:false},sort: ["priority desc","is_archived asc", "ref desc", "id desc"],limit:${limit},offset:${offset}){
            id,
            title,
            summary,
            is_archived,
            comment,
            coverimage,
            location{id, identifier_path,hierarchy,content_type, parent_id},
            metadata{name,published, modified}
          },
          article_count(filter:{_location_parent_id:${parent},_location_is_hidden:false})                      
      }`;
  const data = await serverFetchWithAuth(bodyObj);
  const list = data.data.article;

  const count = data.data.article_count;
  existingData.data = {
    ...existingData.data,
    list: list,
    count: count,
    page: page,
  };
  existingData.serverData = true;
};

export { NewsList, onServerLoad };
