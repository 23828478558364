import { dmeConfig, generalSettings } from "dmeditor";
import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityBridgeSpill } from "./entity";

export const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Spill",
  type: "bridge-spill",
  widgetType: "widget",
  events: {
    createBlock: (): DMEData.Block<EntityBridgeSpill> => {
      return {
        id: nanoid(),
        type: "bridge-spill",
        data: {
          new: true,
          inputData: {
            giver: "n",
            zone: "i",
            boardNo: "",
            north: {
              spade: { data: "" },
              heart: { data: "" },
              diamond: { data: "" },
              club: { data: "" },
            },
            south: {
              spade: { data: "" },
              heart: { data: "" },
              diamond: { data: "" },
              club: { data: "" },
            },
            east: {
              spade: { data: "" },
              heart: { data: "" },
              diamond: { data: "" },
              club: { data: "" },
            },
            west: {
              spade: { data: "" },
              heart: { data: "" },
              diamond: { data: "" },
              club: { data: "" },
            },
            question: "",
            answer: "",
            bidding: {
              list: ["", "", "", "", "", "", "", ""],
              description: "",
            },
          },
          settings: {
            general: {
              padding: 10,
              align: "center",
            },
          },
        },
      };
    },
  },
  settings: [...generalSettings],
};
