import { useEffect, useMemo, useRef, useState } from "react";
import { SpillInputData, TypeBidding } from "./entity";
import { Button, ButtonGroup, Dropdown, Overlay } from "react-bootstrap";
import { css } from "@emotion/css";
import { BiddingText, DivWithBreak } from "./style";

export const Bidding = (props: {
  editMode?: boolean;
  giver: SpillInputData["giver"];
  biddingData: TypeBidding;
  onChange: (biddingData: TypeBidding) => void;
}) => {
  const { editMode, biddingData } = props;

  const onChange = (biddingData: TypeBidding) => {
    if (props.editMode) {
      props.onChange(biddingData);
    }
  };

  const preEmpty = { n: 0, e: 1, s: 2, w: 3 }[props.giver];

  useEffect(() => {
    if (props.giver && props.editMode) {
      const list: Array<string> = [];
      for (let i = preEmpty; i < 8; i++) {
        list.push("");
      }
      onChange({ ...biddingData, list: list });
    }
  }, [props.giver]);

  const rowsNumber = useMemo(() => {
    return (biddingData.list.length + preEmpty) / 4;
  }, [biddingData.list.length]);

  const addRow = () => {
    const newList = [...biddingData.list, "", "", "", ""];
    onChange({ ...biddingData, list: newList });
  };

  const deleteRow = (i: number) => {
    //move on row up
    const startIndex = i * 4 - preEmpty;
    const newList = [...biddingData.list];
    newList.splice(startIndex, 4);
    onChange({ ...biddingData, list: newList });
  };

  const updateBidding = (v: string, index: number) => {
    const list = [...biddingData.list];
    list[index] = v;
    onChange({ ...biddingData, list: list });
  };

  const renderText = (text: string) => {
    if (!text) {
      return "\u00A0";
    }

    let html = text.replaceAll("♠️", "<span>♠</span>");
    html = html.replaceAll("♥️", "<span class='card-heart'>♥</span>");
    html = html.replaceAll("♣️", "<span>♣</span>");
    html = html.replaceAll("♦️", "<span class='card-diamond'>♦</span>");
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  return (
    <div>
      <table
        className={css`
          border-top: 1px solid #cccccc;
          border-left: 1px solid #cccccc;
          th,
          td {
            border-right: 1px solid #cccccc;
            border-bottom: 1px solid #cccccc;
          }

          th {
            text-align: center;
            padding: 5px;
          }

          min-width: 200px;
        `}
      >
        <thead>
          <tr>
            <th>N</th>
            <th>Ø</th>
            <th>S</th>
            <th>V</th>
            {editMode && <th></th>}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rowsNumber }).map((item, i) => (
            <tr>
              <td>
                {editMode && (i > 0 || (i === 0 && preEmpty === 0)) && (
                  <BiddingInput
                    value={biddingData.list[4 * i - preEmpty]}
                    onChange={(v) => updateBidding(v, 4 * i - preEmpty)}
                  />
                )}
                {!editMode && (
                  <BiddingText>
                    {renderText(biddingData.list[4 * i - preEmpty])}
                  </BiddingText>
                )}
              </td>
              <td>
                {editMode && (i > 0 || (i === 0 && preEmpty <= 1)) && (
                  <BiddingInput
                    value={biddingData.list[4 * i + 1 - preEmpty]}
                    onChange={(v) => updateBidding(v, 4 * i + 1 - preEmpty)}
                  />
                )}
                {!editMode && (
                  <BiddingText>
                    {renderText(biddingData.list[4 * i + 1 - preEmpty])}
                  </BiddingText>
                )}
              </td>
              <td>
                {editMode && (i > 0 || (i === 0 && preEmpty <= 2)) && (
                  <BiddingInput
                    value={biddingData.list[4 * i + 2 - preEmpty]}
                    onChange={(v) => updateBidding(v, 4 * i + 2 - preEmpty)}
                  />
                )}
                {!editMode && (
                  <BiddingText>
                    {renderText(biddingData.list[4 * i + 2 - preEmpty])}
                  </BiddingText>
                )}
              </td>
              <td>
                {editMode && (i > 0 || (i === 0 && preEmpty <= 3)) && (
                  <BiddingInput
                    value={biddingData.list[4 * i + 3 - preEmpty]}
                    onChange={(v) => updateBidding(v, 4 * i + 3 - preEmpty)}
                  />
                )}
                {!editMode && (
                  <BiddingText>
                    {renderText(biddingData.list[4 * i + 3 - preEmpty])}
                  </BiddingText>
                )}
              </td>
              {editMode && (
                <td>
                  {rowsNumber > 1 && (
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => deleteRow(i)}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {editMode && (
        <div
          className={css`
            text-align: right;
          `}
        >
          <Button size="sm" variant="link" title="Add a row" onClick={addRow}>
            <i className="bi bi-plus"></i>
          </Button>
        </div>
      )}
      {editMode ? (
        <textarea
          className="form-control"
          onChange={(e) =>
            onChange({ ...biddingData, description: e.target.value })
          }
        >
          {biddingData.description}
        </textarea>
      ) : (
        <DivWithBreak
          className={css`
            margin-top: 10px;
          `}
        >
          {biddingData.description}
        </DivWithBreak>
      )}
    </div>
  );
};

const BiddingInput = (props: {
  value?: string;
  onChange: (v: string) => void;
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  const insert = (v: string) => {
    const input = inputRef.current as any;
    if (input) {
      const position = input.selectionStart;
      const value = props.value;
      let newValue = "";
      if (value) {
        newValue =
          value.slice(0, position) +
          v +
          value.slice(position, value.length - 1);
      } else {
        newValue = v;
      }
      input.value = newValue;
      input.setSelectionRange(position + v.length, position + v.length);
      input.focus();
      props.onChange(newValue);
    }
  };

  return (
    <div onBlur={() => setFocused(false)}>
      <input
        type="text"
        ref={inputRef}
        onFocus={() => setFocused(true)}
        className={css`
          width: 60px;
          font-size: 14px;
          padding: 4px;
          padding-right: 12px;
          border: none;
        `}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value || ""}
      />

      <Overlay target={inputRef} show={focused} placement="right">
        <Dropdown
          as={ButtonGroup}
          style={{ marginLeft: -20 }}
          onFocus={() => setFocused(true)}
        >
          <Dropdown.Toggle style={{ width: 15 }} size="sm" variant="link" />

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => props.onChange("PASS")}>
              PASS
            </Dropdown.Item>
            <Dropdown.Item onClick={() => insert("♠️")}>♠️</Dropdown.Item>
            <Dropdown.Item onClick={() => insert("♥️")}>♥️</Dropdown.Item>
            <Dropdown.Item onClick={() => insert("♦️")}>♦️</Dropdown.Item>
            <Dropdown.Item onClick={() => insert("♣️")}>♣️</Dropdown.Item>
            <Dropdown.Item onClick={() => insert("NT")}>NT</Dropdown.Item>
            <Dropdown.Item onClick={() => props.onChange("")}>
              <i className="fa fa-trash"></i>&nbsp;Tøm
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Overlay>
    </div>
  );
};
